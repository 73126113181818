<template>
  <div>
    <gallery
      :files="files"
      :busy="uploadingCount > 0"
      @delete="deleteFile"
      @upload="uploadFile"
    />
  </div>
</template>

<script>
const Gallery = () => import('@/components/global/gallery/Gallery.vue');

export default {
  name: 'CandleFiles',
  components: {
    Gallery,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loadingCount: 0,
      uploadingCount: 0,
      files: null,
    };
  },
  methods: {
    uploadFile(uploadFile) {
      this.loadingCount++;
      this.uploadingCount++;
      const formData = new FormData();
      formData.append('file', uploadFile);
      formData.append('mtime', uploadFile.lastModified / 1000);
      this.$http
        .post(`/candle/${this.id}/files`)
        .send(formData)
        .then((res) => {
          this.files.push(res.body.file);
        })
        .catch((err) => {
          this.$toast.error(`Failed to upload file: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
          this.uploadingCount--;
        });
    },
    deleteFile(deleteFile) {
      this.loadingCount++;
      this.$http
        .delete(`/candle/${this.id}/files/${deleteFile.id}`)
        .then(() => {
          this.files = this.files.filter((file) => file.id !== deleteFile.id);
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete file: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchFiles() {
      this.loadingCount++;
      this.$http
        .get(`/candle/${this.id}/files`)
        .then((res) => {
          this.files = res.body;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch files: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.fetchFiles();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
